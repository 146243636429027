<template>
  <div
    class="slider-wrapper"
    :class="{ 'no-right': !isHasRight }"
    :style="`height:${height}`"
  >
    <div
      v-for="(item, index) in options"
      :key="index"
      class="slder-item d-flex"
    >
      <div class="slder-text d-flex" :style="`width:${textWidth}px`">
        <div
          v-if="isHasTop"
          class="slider-top"
          :class="
            index === 0
              ? 'oneTop'
              : index === 1
              ? 'twoTop'
              : index === 2
              ? 'threeTop'
              : 'normalTop'
          "
        >
          TOP{{ index + 1 }}
        </div>
        <span>{{ item.label }}</span>
      </div>
      <div
        class="slider-content"
        :class="{ 'no-slider-button': !hasSliderButton }"
      >
        <el-slider
          v-model="values[index]"
          size="mini"
          :class="`slider-${index}`"
          disabled="disabled"
          @change="($value) => $emit('sliderChange', index, $value)"
        ></el-slider>
      </div>
      <div
        v-if="isHasRight"
        class="slider-right"
        :style="`width:${rightTextWidth}px`"
      >
        {{ item.rightText || '' }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    height: { type: String, default: '100%' },
    options: {
      type: Array,
      default: () => {
        return []
      },
    },
    textWidth: { type: Number, default: 100 },
    isHasTop: { type: Boolean, default: true }, // 是否有top内容
    isHasRight: { type: Boolean, default: true }, // 是否有右侧内容
    rightTextWidth: { type: Number, default: 80 }, // 右侧文字宽度
    hasSliderButton: { type: Boolean, default: true }, // 是否有滑块
  },
  data() {
    return {
      values: [],
    }
  },
  watch: {
    options: {
      handler(newVal) {
        this.values = newVal.map((item) => Math.floor(item.value))
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.slider-wrapper {
  overflow-y: auto;
  &.no-right {
    padding-right: 18px;
  }
  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #85ace9;
  }

  &::-webkit-scrollbar-track {
    background-color: #020927;
  }
}
.slder-item {
  height: 40px;
  line-height: 40px;
  .slder-text {
    font-size: 14px;
    align-items: center;
    .slider-top {
      height: 25px;
      line-height: 25px;
      padding: 1px 2px;
      margin-right: 10px;
      background: rgba(201, 193, 206, 0.28);
      &.oneTop {
        background: rgba(120, 165, 236, 0.55);
      }
      &.twoTop {
        background: rgba(101, 195, 223, 0.55);
      }
      &.threeTop {
        background: rgba(219, 140, 230, 0.55);
      }
    }
  }
  .slider-content {
    flex: 1;
    // padding-right: 6px;
    &.no-slider-button {
      ::v-deep .el-slider__button-wrapper {
        display: none;
      }
    }
    ::v-deep .el-slider__runway {
      background: transparent;
    }
    ::v-deep .el-slider__button {
      height: 13px;
      width: 13px;
      border: 1px solid rgba(255, 255, 255, 0.64);
      background: rgba(255, 255, 255, 0.5);
      border-radius: 0 !important;
    }
    .slider-0 ::v-deep .el-slider__bar {
      background: linear-gradient(90deg, #307ef8, #a1c0f1 100%);
    }
    .slider-1 ::v-deep .el-slider__bar {
      background: linear-gradient(90deg, #5fd5ec, #a1e8f1 100%);
    }
    .slider-2 ::v-deep .el-slider__bar {
      background: linear-gradient(90deg, #b41ec3, #f3b6f4 100%);
    }
    ::v-deep .el-slider__bar {
      background: linear-gradient(90deg, #888aa1, #f3e9f3 100%);
    }
  }
  .slider-right {
    margin-left: 15px;
  }
}
</style>
